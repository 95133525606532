import { useEffect, useState, useRef } from 'react';
import NewsService from '~/api-services/NewsService';
import {News, NewsDeleted} from '~/api-services/NewsService/types';
import { appSettingsSelector, userIsLoggedInSelector } from '~/state/selectors';
import { useAppSelector } from './useAppSelector';
import { useIsAppActive } from './useIsAppActive';
import LocalStorage from '../state/localStorage';
import StreamingService from "~/api-services/StreamingService";

export const useNews = () => {
    const [newsArray, setNewsArray] = useState<News[]>([]);
    const [lastId, setLastId] = useState<string>('');
    const [modified, setModified] = useState<number>(0);
    const [newsCount, setNewsCount] = useState<number>(0);
    const [trigger, setTrigger] = useState<number>(0);
    const reloadDataPollRef = useRef<NodeJS.Timer>();
    const isAppActive = useIsAppActive();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);
    const { language } = useAppSelector(appSettingsSelector);

    useEffect(() => {
        if (isAppActive && isLoggedIn) {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
            reloadDataPollRef.current = setInterval(() => {
                loadData();
            }, 60000); // re-fetch 60 seconds
            loadData();
        } else {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        }
        return () => {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        };
    }, [isLoggedIn, isAppActive]);

    useEffect(() => {
        if (trigger === 1) {
            loadData();
            setTrigger(2);
        }
    }, [trigger]);

    useEffect(() => {
        StreamingService.subscribeNews(onNews);
        StreamingService.subscribeNewsDeleted(onNewsDeleted);
        return () => {
            StreamingService.unsubscribeNews(onNews);
            StreamingService.unsubscribeNewsDeleted(onNewsDeleted);
        };
    }, []);

    function onNews(n: News): void {
        replaceNews(n);
    }

    function onNewsDeleted(n: NewsDeleted): void {
        deleteNews(n);
    }

    async function replaceNews(n: News) {
        // get news array from local storage
        const localStorageData = await LocalStorage.news.get() || [];

        // convert existing news array to map
        let newsMap = localStorageData.reduce<Map<string, News>>(function(map, obj) {
            map.set(obj.id, obj);
            return map;
        }, new Map<string, News>());

        newsMap.set(n.id, n);

        let list : News[] = [];
        for (let [_, v] of newsMap) {
            list.push(v);
        }

        // sort array newest to oldest
        list.sort(function(a,b) {
            if (a.date > b.date) {
                return -1;
            }
            if (b.date > a.date) {
                return 1;
            }
            return 0;
        });

        changeList(list);
    }

    async function deleteNews(n: NewsDeleted) {
        // get news array from local storage
        const localStorageData = await LocalStorage.news.get() || [];

        // convert existing news array to map
        let newsMap = localStorageData.reduce<Map<string, News>>(function(map, obj) {
            map.set(obj.id, obj);
            return map;
        }, new Map<string, News>());

        newsMap.delete(n.id);

        let list : News[] = [];
        for (let [_, v] of newsMap) {
            list.push(v);
        }

        // sort array newest to oldest
        list.sort(function(a,b) {
            if (a.date > b.date) {
                return -1;
            }
            if (b.date > a.date) {
                return 1;
            }
            return 0;
        });

        changeList(list);
    }

    async function loadData() {
        if (lastId) {
            const { status, data } = await NewsService.reloadAllNews2V2({ id: lastId, count: newsCount, modified: modified });
            if (status === 200 && data.status) {
                if (data.returnData.action == 'none') {
                    // no change - do nothing
                } else if (data.returnData.action == 'replace') {
                    changeList(data.returnData.list);
                } else if (data.returnData.action == 'merge') {
                    // get news array from local storage
                    const localStorageData = await LocalStorage.news.get() || [];

                    // convert existing news array to map
                    let newsMap = localStorageData.reduce<Map<string, News>>(function(map, obj) {
                        map.set(obj.id, obj);
                        return map;
                    }, new Map<string, News>());

                    // convert added or updated news array to map
                    const newOrUpdate = data.returnData.list || [];
                    newOrUpdate.forEach(function(n: News) {
                        newsMap.set(n.id, n);
                    })

                    // convert keep ids array to map
                    let keepIdsMap = data.returnData.keepIds.reduce<Map<string, boolean>>(function(map, id) {
                        map.set(id, true);
                        return map;
                    }, new Map<string, boolean>());

                    // collect only values, which have a key in keepIdsMap
                    let list : News[] = [];
                    for (let [k, v] of newsMap) {
                        if (keepIdsMap.has(k)) {
                            list.push(v);
                        }
                    }

                    // sort array newest to oldest
                    list.sort(function(a,b) {
                        if (a.date > b.date) {
                            return -1;
                        }
                        if (b.date > a.date) {
                            return 1;
                        }
                        return 0;
                    });

                    changeList(list);
                } else {
                    // not supported action - clear cache and force get initial data
                    await LocalStorage.news.set([]);
                    setLastId('');
                }
            }
        } else {
            const localStorageData = await LocalStorage.news.get();
            if (localStorageData && localStorageData.length > 0) {
                changeList(localStorageData, false);
                if (trigger === 0) {
                    setTrigger(1);
                }
            } else {
                const { status, data } = await NewsService.getAllNews2({ language: language.toUpperCase() });
                if (status === 200 && data.status) {
                    changeList(data.returnData);
                }
            }
        }
    }

    function changeList(list: News[], save: boolean = true) {
        setNewsArray(list);
        if (save) {
            // we do not want to wait
            LocalStorage.news.set(list);
        }
        if (list && list.length > 0) {
            setLastId(list[0].id);
        }
        const newModified = list.reduce((a, n) => Math.max(a, n.modified || 0), 0);
        setModified(newModified);
        setNewsCount(list.length);
    }

    return {
        newsArray,
    };
};
